<template>
  <div class="home">
    <div class="demo-image__lazy">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_01.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_02.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_03.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_04.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_05.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_06.png" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_07.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_08.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_09.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_10.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_11.jpg" lazy width="100%" height="100%">
      <img src="../../../public/images/xqy/低功耗wifi-BMW2/images/MW2_12.png" lazy width="100%" height="100%">
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>

</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "低功耗WiFi-BMW2",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>
.demo-image__lazy {
  display: block;
  min-height: 100px;
  margin-bottom: 10px;
}
</style>